import(/* webpackMode: "eager" */ "/Users/zixuan/Documents/xiangqian-web/app/components/footer.module.scss");
import(/* webpackMode: "eager" */ "/Users/zixuan/Documents/xiangqian-web/app/components/homeFAQList.js");
import(/* webpackMode: "eager" */ "/Users/zixuan/Documents/xiangqian-web/app/components/homeTextArea.jsx");
import(/* webpackMode: "eager" */ "/Users/zixuan/Documents/xiangqian-web/app/components/loginBtn.js");
import(/* webpackMode: "eager" */ "/Users/zixuan/Documents/xiangqian-web/app/components/recommendBtnList.js");
import(/* webpackMode: "eager" */ "/Users/zixuan/Documents/xiangqian-web/app/icons/home_desc_icon_1.svg");
import(/* webpackMode: "eager" */ "/Users/zixuan/Documents/xiangqian-web/app/icons/home_desc_icon_2.svg");
import(/* webpackMode: "eager" */ "/Users/zixuan/Documents/xiangqian-web/app/icons/home_desc_icon_3.svg");
import(/* webpackMode: "eager" */ "/Users/zixuan/Documents/xiangqian-web/app/icons/home_desc_icon_4.svg");
import(/* webpackMode: "eager" */ "/Users/zixuan/Documents/xiangqian-web/app/icons/home_triangle_icon.svg");
import(/* webpackMode: "eager" */ "/Users/zixuan/Documents/xiangqian-web/app/icons/home_triangle_white_icon.svg");
import(/* webpackMode: "eager" */ "/Users/zixuan/Documents/xiangqian-web/app/icons/magnifier.svg");
import(/* webpackMode: "eager" */ "/Users/zixuan/Documents/xiangqian-web/app/icons/main_logo.svg");
import(/* webpackMode: "eager" */ "/Users/zixuan/Documents/xiangqian-web/app/icons/report.svg");
import(/* webpackMode: "eager" */ "/Users/zixuan/Documents/xiangqian-web/app/icons/workflow.svg");
import(/* webpackMode: "eager" */ "/Users/zixuan/Documents/xiangqian-web/app/img/bnu.png");
import(/* webpackMode: "eager" */ "/Users/zixuan/Documents/xiangqian-web/app/img/caoss.png");
import(/* webpackMode: "eager" */ "/Users/zixuan/Documents/xiangqian-web/app/img/cas.png");
import(/* webpackMode: "eager" */ "/Users/zixuan/Documents/xiangqian-web/app/img/cqu.png");
import(/* webpackMode: "eager" */ "/Users/zixuan/Documents/xiangqian-web/app/img/fudanUniversity.png");
import(/* webpackMode: "eager" */ "/Users/zixuan/Documents/xiangqian-web/app/img/nanjingUniversity.png");
import(/* webpackMode: "eager" */ "/Users/zixuan/Documents/xiangqian-web/app/img/pekingUniversity.png");
import(/* webpackMode: "eager" */ "/Users/zixuan/Documents/xiangqian-web/app/img/renminUniversityofChina.png");
import(/* webpackMode: "eager" */ "/Users/zixuan/Documents/xiangqian-web/app/img/shanghaiJiaoTongUniversity.png");
import(/* webpackMode: "eager" */ "/Users/zixuan/Documents/xiangqian-web/app/img/sysu.png");
import(/* webpackMode: "eager" */ "/Users/zixuan/Documents/xiangqian-web/app/img/tsinghuaUniversity.png");
import(/* webpackMode: "eager" */ "/Users/zixuan/Documents/xiangqian-web/app/img/whu.png");
import(/* webpackMode: "eager" */ "/Users/zixuan/Documents/xiangqian-web/app/img/xjtu.png");
import(/* webpackMode: "eager" */ "/Users/zixuan/Documents/xiangqian-web/app/img/zhejiangUniversity.png");
import(/* webpackMode: "eager" */ "/Users/zixuan/Documents/xiangqian-web/app/page.module.scss");
import(/* webpackMode: "eager" */ "/Users/zixuan/Documents/xiangqian-web/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/Users/zixuan/Documents/xiangqian-web/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js")